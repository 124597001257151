body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.transfer-list::-webkit-scrollbar {
  height: 0px;
}
.table-responsive::-webkit-scrollbar {
  height: 0px;
}
/* Common styles for all screen sizes */
@media screen and (min-width: 1200px) {
  .main-div {
    flex-direction: column;
    width: 100%;
    row-gap: 20px;
  }
  .bottom-div {
    flex-direction: row;
    width: 100%;
    column-gap: 20px;
  }
  .bottom-div-left {
    flex-direction: row;
    width: 50%;
    height: auto;
    column-gap: 20px;
  }
  .bottom-div-right {
    flex-direction: row;
    width: 50%;
    column-gap: 20px;
  }
}
@media screen and (min-width: 900px) and (max-width: 1199px) {
  .main-div {
    flex-direction: column;
    width: 100%;
    row-gap: 20px;
  }
  .bottom-div {
    flex-direction: row;
    width: 100%;
    column-gap: 20px;

  }
  .bottom-div-left {
    flex-direction: row;
    column-gap: 20px;
    width: 50%;
  }
  .bottom-div-right {
    flex-direction: row;
    width: 50%;
    column-gap: 20px;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .main-div {
    flex-direction: column;
    width: 100%;
    row-gap: 20px;
  }
  .bottom-div {
    flex-direction: column;
    width: 100%;
    row-gap: 20px;
  }
  .bottom-div-left {
    flex-direction: row;
    height: 50%;
    column-gap: 20px;
  }
  .bottom-div-right {
    flex-direction: row;
    column-gap: 20px;
  }
}
a{
  color:black;
}
