.pdf {
  width: 90%;
}
/* 
@media only screen and (max-width: 1200px) {
  .pdf {
    width: 450px;
  }
} */

@media only screen and (max-width: 768px) {
  .pdf {
    width: 100%;
}
}
